<template>
  <v-app>
    <helps />
    <hero-view />
  </v-app>
</template>

<script>
// Extensions
import View from "@/views/View";

export default {
  name: "BibleHelp",

  metaInfo: { title: "Bible Help" },

  components: {
    Helps: () => import("@/components/Helps"),
    HeroView: () => import("@/views/sections/Hero")
  },
  extends: View,

  props: {
    id: {
      type: String,
      default: "bible-help"
    }
  }
};
</script>
